import React from 'react';
import './App.css';
import {TweenMax} from 'gsap';
import homebtnicon from './images/homebtn.png'
import cover from './images/cover2.png';
import exp1 from './images/exp1.png'
import exp2 from './images/exp2.png'
import exp3 from './images/exp3.png'
import start from './images/start.png'
import si from './images/si1.png'
import siSelect from './images/si2.png'
import handle from './images/handle.png'
import handle2 from './images/handle2.png'
import food from './images/food.png';
import transport from './images/transport.png';
import littleLuxuries from './images/little-luxuries.png'
import book from './images/books.png'
import memory from './images/memory.png'
import singaporeCulture from './images/singapore-culture.png'
import food1 from './images/CC_Art Science Museum PLAY(icons)-02.png'
import food2 from './images/CC_Art Science Museum PLAY(icons)-01.png'
import food3 from './images/CC_Art Science Museum PLAY(icons)-03.png'
import food4 from './images/CC_Art Science Museum PLAY(icons)-04.png'
import food5 from './images/CC_Art Science Museum PLAY(icons)-05.png'
import food6 from './images/CC_Art Science Museum PLAY(icons)-06.png'
import transport1 from './images/CC_Art Science Museum PLAY(icons)-07.png'
import transport2 from './images/CC_Art Science Museum PLAY(icons)-08.png'
import transport3 from './images/CC_Art Science Museum PLAY(icons)-09.png'
import transport4 from './images/CC_Art Science Museum PLAY(icons)-10.png'
import transport5 from './images/CC_Art Science Museum PLAY(icons)-11.png'
import transport6 from './images/CC_Art Science Museum PLAY(icons)-12.png'
import lux1 from './images/CC_Art Science Museum PLAY(icons)-13.png'
import lux2 from './images/CC_Art Science Museum PLAY(icons)-14.png'
import lux3 from './images/CC_Art Science Museum PLAY(icons)-15.png'
import lux4 from './images/CC_Art Science Museum PLAY(icons)-16.png'
import lux5 from './images/CC_Art Science Museum PLAY(icons)-17.png'
import lux6 from './images/CC_Art Science Museum PLAY(icons)-18.png'
import book1 from './images/CC_Art Science Museum PLAY(icons)-19.png'
import book2 from './images/CC_Art Science Museum PLAY(icons)-20.png'
import book3 from './images/CC_Art Science Museum PLAY(icons)-21.png'
import book4 from './images/CC_Art Science Museum PLAY(icons)-22.png'
import book5 from './images/CC_Art Science Museum PLAY(icons)-23.png'
import book6 from './images/CC_Art Science Museum PLAY(icons)-24.png'
import memory1 from './images/CC_Art Science Museum PLAY(icons)-25.png'
import memory2 from './images/CC_Art Science Museum PLAY(icons)-26.png'
import memory3 from './images/CC_Art Science Museum PLAY(icons)-27.png'
import memory4 from './images/CC_Art Science Museum PLAY(icons)-28.png'
import memory5 from './images/CC_Art Science Museum PLAY(icons)-29.png'
import memory6 from './images/CC_Art Science Museum PLAY(icons)-30.png'
import culture1 from './images/CC_Art Science Museum PLAY(icons)-31.png'
import culture2 from './images/CC_Art Science Museum PLAY(icons)-32.png'
import culture3 from './images/CC_Art Science Museum PLAY(icons)-33.png'
import culture4 from './images/CC_Art Science Museum PLAY(icons)-34.png'
import culture5 from './images/CC_Art Science Museum PLAY(icons)-35.png'
import culture6 from './images/CC_Art Science Museum PLAY(icons)-36.png'
import foodselect from './images/food-select.png'
import transportselect from './images/transport-select.png'
import luxselect from './images/lux-select.png'
import bookselect from './images/book-select.png'
import memoryselect from './images/memory-select.png'
import cultureselect from './images/culture-select.png'
import capsule from './images/capusle2.png'
import capsule2 from './images/complete-capsule.png'
import AgreeTick from './images/agree-tick.png'
import AgreeCross from './images/agree-cross.png'
import submitbtn from './images/submit.png'
import homeBtn from './images/home.png'
import swp from './images/swp.png'
import timeup from './images/timeup.png'
import readyText from './images/ready-text.png'
import skip from './images/skip.png'
import finger from './images/finger.png'
import bgmusic from './images/sound/bg.mp3'
import startsound from './images/sound/start.mp3'
import pon from './images/sound/capsuleon.wav'
import selectsound from './images/sound/select.mp3'
import footer from './images/footer.png'
import footer1 from './images/footer-1.png'
import footer2 from './images/footer-2.png'
import ss from './images/ss.png'
import openSocket from 'socket.io-client';
import countLabel from './images/count-label.png'
import qrCode from './images/frame.png'
// import { throwStatement } from '@babel/types';
// import { Tween } from 'react-gsap';
const socket = openSocket('https://chat.api.95shop.io');

class App extends React.Component{
  constructor(){
    super();
    this.myRef=React.createRef();
    this.state={
      bgsound:null,
      timer:60,
      count:0,
      caret: 0,
      input_no:'',
      page:'Home',
      type:[],
      data:[],
      text: {name:'',
            message:'',
            email:''},
      email_valids: false,
      agrees: [false],
      convert_image:[],
      idle_confirm:false
    }
  }
  countRefresh=()=>{
    fetch('https://artscience.wunderfauks.com/wp/wp-admin/admin-ajax.php?action=get_capsule')
    .then(response => response.json())
    .then(data => {
      this.setState({data})
      console.log("data",data)
    this.setHomeCapusleOrigin("#capsule5")
    this.setHomeTextOrigin("#home-text-0")
    console.log("length",this.state.data.length)
    if(this.state.data.length===1)
    {
      this.showCapsule("#capsule5")
      this.showHomeText("#home-text-0")
      setTimeout(() => {
        this.moveCapsule5('#capsule5')
        this.moveHomeText5('#home-text-0')
        this.changeFont('#home-name-0')
        this.changeFont2('#home-message-0')
      }, 1000);
    }
    if(this.state.data.length===2)
    {
      this.showCapsule("#capsule5,#capsule6")
      this.showHomeText("#home-text-0,#home-text-1")
      setTimeout(() => {
        this.moveCapsule5('#capsule5')
        this.moveHomeText5('#home-text-0')
        this.changeFont('#home-name-0')
        this.changeFont2('#home-message-0')
      }, 1000);
    }
    if(this.state.data.length===3)
    {
      this.showCapsule("#capsule6,#capsule7,#capsule5")
      this.showHomeText("#home-text-0,#home-text-1,#home-text-2")
      setTimeout(() => {
        this.moveCapsule5('#capsule5')
        this.moveHomeText5('#home-text-0')
        this.changeFont('#home-name-0')
        this.changeFont2('#home-message-0')
      }, 1000);
    }
    if(this.state.data.length>=4)
    {
      this.showCapsule("#capsule5,#capsule6,#capsule7,#capsule8")
      this.showHomeText("#home-text-0,#home-text-1,#home-text-2,#home-text-3")
      setTimeout(() => {
        this.moveCapsule5('#capsule5')
        this.moveHomeText5('#home-text-0')
        this.changeFont('#home-name-0')
        this.changeFont2('#home-message-0')
      }, 1000);
    }
    })
  }
  componentDidMount=()=>{
    document.addEventListener('contextmenu', event => event.preventDefault());
    this.countRefresh();
    let _this=this
    socket.on('hey', function(data) {
      // socket.emit('hey', 'Hello World from client');
      _this.countRefresh();
    });

   socket.on('broad', function(data) {
      _this.countRefresh()
   });
  }
  
  decreaseAntiSpeed=(a)=>{
    TweenMax.to(a,0.1,{animation:'1'})
  }
  increaseAntiSpeed=(a)=>{
    TweenMax.to(a,0.1,{animation:'0.5'})
  }

  onBegin = () => {
    this.increaseAntiSpeed(".ring_21,.ring_22,.ring_23,.ring_24,.ring_25")
    this.hideitem("#home-btn-icon")
    this.antimationSound();
    setTimeout(()=>{
      this.handleChangeNextPage('#home', '#explanation', 'Explanation', 'Home to Explanation')
      this.slideOne();
      this.hideCategories("#start-btn")
      this.showitem("#home-btn-icon")
    },2000);
    setTimeout(()=>{
      this.decreaseAntiSpeed(".ring_21,.ring_22,.ring_23,.ring_24,.ring_25")
    },2500)
  }
  readyToHome=()=>{
    clearInterval(this.timerInterval)
    setTimeout(() => {
      this.hideSelect("#ready-text,#categories,#ctg-select-1,#ctg-select-2,#ctg-select-3,#ctg-select-4,#ctg-select-5,#ctg-select-6,#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
  }, 1000);
  
    setTimeout(() => {
      this.closeCover("#handle2");
      this.hideHandle('#handle2');
    }, 2000);
    setTimeout(() => {
      this.showHandle('#handle');
      this.showCover('#cover');
      this.moveHandle("#handle");
    }, 3000);
    setTimeout(() => {
      this.toHome3();
    }, 4000);
  }
  timer=()=>{
    this.timerInterval = setInterval( () => {
        let { timer } = this.state
        this.setState({ timer: timer - 1 })
        console.log(this.state.timer)
        
        if(this.state.timer===0)
         {
           setTimeout(() => {
             this.hideSelect("#ctg-select-1,#ctg-select-2,#ctg-select-3,#ctg-select-4,#ctg-select-5,#ctg-select-6,#ready-text")
         }, 1000);
         setTimeout(() => {
           this.hideCategories("#categories,#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
           }, 2000);
           setTimeout(() => {
             this.showCategories("#timeup")
           }, 3000);
           setTimeout(() => {
             this.hideCategories("#timeup")
             this.openCapsuleSound();
             this.closeCover("#handle2");
             this.hideHandle('#handle2');
           }, 4000);
           setTimeout(() => {
             this.showHandle('#handle');
             this.showCover('#cover');
             this.moveHandle("#handle");
           }, 5000);
           setTimeout(() => {
             this.toHome3();
           }, 6000);
         }
         if(this.state.timer === 0) {
          clearInterval(this.timerInterval)
         }
   
  },1000)
  
  }




  toReady= () => {
    this.setState({timer:60})
    this.setState({count:0})
    this.setState({type:[]})
    this.flipIcon2("#ctg-1,#ctg-2,#ctg-3,#ctg-4,#ctg-5,#ctg-6");
    this.setState(prevState=>{
      let text=Object.assign({},prevState.text);
      text.name='';
      text.message='';
      text.email=''
      return {text}
    })
    this.setState({agrees:[false]})
    this.setState({message:''})
    this.setState({email:''})
    this.moveTextCapsuleToOrigin("#capsule2")
    this.hideCategories("#text-area,#keyboard")
    this.handleChangeNextPage('#explanation', '#ready', 'ready', 'Start');
     setTimeout(() => {
      this.openCapsuleSound();
      this.openCapsule("#handle");
      this.hideHandle('#handle');
      this.hideHandle("#cover");
    }, 500);
    setTimeout(() => {
      this.showHandle('#handle2');
    }, 1500);
    
    setTimeout(() => {
      this.openCover("#handle2");    
    }, 2000);
    setTimeout(() => {
      this.timer();
      this.showCategories("#ready-text,#categories")
      this.hideCategories("#get-ready,#food,#transport,#lux,#book,#memory,#culture")
      this.showitem("#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
      this.moveOrigin("#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
      this.showCapsule("#ctg-1,#ctg-2,#ctg-3,#ctg-4,#ctg-5,#ctg-6,#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")

      }, 2500);
    setTimeout(() => {
      this.flipIcon("#ctg-1,#ctg-2,#ctg-3,#ctg-4,#ctg-5,#ctg-6");
      this.showHandle("#categoires-label")
    }, 3000);
    
  }
  flipIcon=(a)=>{
   TweenMax.to(a,0.5,{transform:'rotateY(0)'})
  }
  flipIcon2=(a)=>{
    TweenMax.to(a,0.5,{transform:'rotateY(180deg)'})
   }
  setHomeCapusleOrigin=(a)=>{
      TweenMax.to(a,0.1,{width:'55%',top:'900px',left:'25%'})
  }
  setHomeTextOrigin=(a)=>{
    TweenMax.to(a,0.1,{width:'35%',top:'935px',left:'35%'})
};
toPoster=()=>{
  this.setHomeCapusleOrigin("#capsule5")
  this.setHomeTextOrigin("#home-text-0")
  console.log("final data length",this.state.data.length)
  this.handleChangeNextPage('#hasharea','#poster', 'Poster','Hash to Poster');
  setTimeout(() => {
    this.toHome()
  }, 5000);
}
toSkip=()=>{
  clearTimeout(this.idle_timer);
  this.setState({
    idle_confirm:true
  })
    this.handleChangeNextPage('#textarea','#poster','Poster','Text to Poster');
    let {data,text}=this.state
    data.splice(0,0, {name: text.name, message: text.message, email: text.email})
    // data.push({name: text.name, message: text.message, email: text.email})
    this.setState({data})
    console.log("final array ",data)
    setTimeout(() => {
      this.toHome()
    }, 5000);
}
  toHome=()=>{
    
    this.handleChangeNextPage('#poster','#home', 'Home','Poster to Home');
    this.countRefresh()
    
  }
  toHome2=()=>{
    this.handleChangeNextPage('#explanation','#home', 'Home','Explanation to Home');
  }
  toHome3=()=>{
    this.handleChangeNextPage('#ready','#home', 'Home','Ready to Home');
    this.hideCategories("#timeup")
  }
  toHome4=()=>{
    clearTimeout(this.idle_timer);
    this.handleChangeNextPage('#textarea','#home', 'Home','Textarea to Hom');
  }
  toText=()=>{
    this.handleChangeNextPage('#ready','#textarea','Textarea','Ready to Textarea');
  }
  
  toHash=()=>{
   
    this.handleChangeNextPage('#textarea','#hasharea','Hasharea','Text to Hash');
    let {data,text}=this.state
    data.splice(0,0, {name: text.name, message: text.message, email: text.email})
    this.setState({data})
    console.log("final array ",data)
    
  }
  hideHandle=(a)=>{
    TweenMax.to(a,1,{display:'none'});
  }
  showHandle=(a)=>{
    TweenMax.to(a,1,{display:'block'})
  }
  moveHandle=(a)=>{
    TweenMax.to(a,1,{top:'-1250px'})
  }
  showCover=(a)=>{
    TweenMax.to(a,0,{display:'block',left:'383px'})
  }
  openCover=(a)=>{
    TweenMax.to(a,1,{top: '-2590px'});
  }
  openCapsule=(x)=>{
    TweenMax.to(x,1,{top:'-35px'});
  }
  closeCover=(a)=>{
    TweenMax.to(a,1,{top:'-1360px'})
  }
  showCapsule=(a)=>{
    TweenMax.to(a,0.3,{display:'inline'})
  }
  showHomeText=(a)=>{
    TweenMax.to(a,0.3,{display:'inline'})
  }

  moveCapsule5=(a)=>{
    TweenMax.to(a,1,{width:'40%',left:'10%',top:'1300px'})
  }
  

  moveHomeText5=(a)=>{
    TweenMax.to(a,1,{display:'inline',left:'12%',top:'1328px'})
  }
  


  changeFont=(a)=>{
    TweenMax.to(a,1,{fontSize:'35px'})
  }
  changeFont2=(a)=>{
    TweenMax.to(a,1,{fontSize:'20px'})
  }
  showCategories=(y)=>{
    TweenMax.to(y,1.5,{display:'block'});
  }
  hideCategories=(z)=>{
    TweenMax.to(z,0.1,{display:'none'});
  }
  hideCategories2=(z)=>{
    TweenMax.to(z,1,{display:'none'});
  }
  toFood=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#food");
    setTimeout(() => {
      this.showCategories("#food-label")
    }, 1000);
  }
  toTransport=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#transport");
    setTimeout(() => {
      this.showCategories("#transport-label")
    }, 1000);
  }
  toLuxuries=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#lux");
    setTimeout(() => {
      this.showCategories("#lux-label")
    }, 1000);
  }
  toBook=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#book");
    setTimeout(() => {
      this.showCategories("#book-label")
    }, 1000);
  }
  toMemory=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#memory");
    setTimeout(() => {
      this.showCategories("#memory-label")
    }, 1000);
  }
  toCulture=()=>{
    this.hideCategories("#categories,#categoires-label");
    this.showCategories("#culture");
    setTimeout(() => {
      this.showCategories("#culture-label")
    }, 1000);
  }
  handleChangeNextPage = (a, b, page = '', click = '') => {
    TweenMax.to(a, 0.3, {display: 'none'});
    TweenMax.to(b, 0.3, {display: 'block' });
    this.handleEvent(`${click} Button Click`, this.state.page);
    this.setState({ page: page });
    this.time = 0;
  }
  handleEvent = (label, page, extra = '') => {
    const panel = this.getQueryStringValue('panel')
    let data = new FormData()
    data.append("action", 'update_count')
    data.append("label", label)
    data.append("frame", page + ' Page')
    data.append("panel", panel)
    data.append("add_option", extra)
    fetch("https://artscience.wunderfauks.com/wp/wp-admin/admin-ajax.php", {
       method: "POST",
       body: data,
    })
    .then(response => response.json())
    .then( result => {})
 }
 getQueryStringValue =  (key) => {
  return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}
  showSelect=(a)=>{
    TweenMax.to(a,0.3,{ display:'inline'})
  }
  hideSelect=(a)=>{
    TweenMax.to(a,1,{ display:'none'})
  }
  
  changeToText=()=>{
    console.log(this.idle_timer,"idle timer 1")
    setTimeout(() => {
        this.hideSelect("#ctg-select-1,#ctg-select-2,#ctg-select-3,#ctg-select-4,#ctg-select-5,#ctg-select-6,#ready-text")
    }, 100);
    setTimeout(() => {
      this.hideCategories("#categories")
      }, 500);
      setTimeout(() => {
        this.openCapsuleSound();
        this.hideCategories("#timeup")
        this.closeCover("#handle2");
        this.hideHandle('#handle2');
      }, 1000);
      setTimeout(() => {
        this.showHandle('#handle');
        this.showCover('#cover');
        this.moveHandle("#handle");
      }, 2000);
      setTimeout(() => {
        this.toText();
      }, 3000);
      setTimeout(() => {
        this.moveTextCapsule('#capsule2');
      }, 4000);
      setTimeout(() => {
        this.showText("#text-area");
      }, 5000);
      setTimeout(() => {
        this.showCategories("#keyboard")
        this.popupOpen()
      }, 6000);
      console.log("agree",this.state.agrees)
      
  }
  idle=()=>{
    this.idle_timer=setTimeout(()=>{
      this.toHome4();
      console.log("idle timer 1",this.idle_timer)
    },66000)
    
      
    }    
  moveTextCapsule=(a)=>{
    TweenMax.to(a,1,{width: '70%',top: '400px',transform: 'rotate(0deg)',left:'16%'})
  }
  moveTextCapsuleToOrigin=(a)=>{
    TweenMax.to(a,1,{width: '94%',top: '1640px',transform: 'rotate(270deg)',left:'33px'})
  }
  showText=(a)=>{
    TweenMax.to(a,1,{display:'block'});
  }
  moveItem1=(a)=>{
     TweenMax.to(a,0.5,{width:'320px',left:'260px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  moveItem22=(a)=>{
    TweenMax.to(a,0.5,{transform:'rotateX(0deg)'})
 }
  chooseItem1=(a)=>{
    TweenMax.to(a,0.5,{width:'100px',top:'800px',left:'285px'})
  }
  moveOrigin=(a)=>{
    TweenMax.to(a,1,{width:'240px',zIndex:'1',left:'0px',top:'0px'})
  }
  moveOrigin2=(a)=>{
    TweenMax.to(a,1,{display:'inline-block',position:'relative',width:'240px',zIndex:'1',left:'0',top:'0'})
    
  }
  hideitem=(a)=>{
    TweenMax.to(a,0.1,{visibility: 'hidden'})
  }
  showitem=(a)=>{
    TweenMax.to(a,0.1,{visibility: 'unset',pointerEvents:'unset'})
  }
  selectFood1=()=>{

    let {type}=this.state
    type.push('food-1')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-1")
    this.hideitem("#food-2,#food-3,#food-4,#food-5,#food-6")
    this.moveItem1("#food-1");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#food-1");
    },500)
    setTimeout(() => {
      this.hideCategories("#food,#categories,#food-label")
      this.chooseItem1("#food-1")
      this.selectItemSound();
      this.hideCategories2("#food");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-1")
    }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5) //note
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }

  
  selectFood2=()=>{
    let {type}=this.state
    type.push('food-2')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-1")
    this.hideitem("#food-1,#food-3,#food-4,#food-5,#food-6")
    this.moveItem2("#food-2");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#food-2");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#food,#categories,#food-label")
      this.chooseItem2("#food-2")
      this.hideCategories2("#food");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-1")
    }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  moveItem2=(a)=>{
    TweenMax.to(a,0.5,{width:'320px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  chooseItem2=(a)=>{
    TweenMax.to(a,0.5,{width:'100px',top:'800px',left:'25px'})
  }
  selectFood3=()=>{
    let {type}=this.state
    type.push('food-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-1")
      this.hideitem("#food-1,#food-2,#food-4,#food-5,#food-6")
      this.moveItem3("#food-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#food-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#food,#categories,#food-label")
        this.chooseItem3("#food-3")
        this.hideCategories2("#food");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-1")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  moveItem3=(a)=>{
    TweenMax.to(a,0.5,{width:'320px',left:'-260px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  chooseItem3=(a)=>{
    TweenMax.to(a,0.5,{top:'800px',width:'100px',left:'-220px'})
  }
  selectFood4=()=>{
    let {type}=this.state
    type.push('food-4')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-1")
    this.hideitem("#food-1,#food-2,#food-3,#food-5,#food-6")
    this.moveItem4("#food-4");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#food-4");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#food,#categories,#food-label")
      this.chooseItem4("#food-4")
      this.hideCategories2("#food");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-1")
    }, 2000);
    let {count} = this.state
    this.setState({count: count +1})
    if(this.state.count===5)
    {
      this.idle()
      clearInterval(this.timerInterval)
      setTimeout(() => {
        this.changeToText();
      }, 2500);
    }
  }
  moveItem4=(a)=>{
    TweenMax.to(a,0.5,{width:'320px',left:'250px',top:'-200px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  chooseItem4=(a)=>{
    TweenMax.to(a,0.5,{top:'500px',width:'100px'})
  }
  selectFood5=()=>{
    let {type}=this.state
    type.push('food-5')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-1")
    this.hideitem("#food-1,#food-2,#food-3,#food-4,#food-6")
    this.moveItem5("#food-5");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#food-5");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#food,#categories,#food-label")
      this.chooseItem5("#food-5")
      this.hideCategories2("#food");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-1")
    }, 2000);
    let {count} = this.state
    this.setState({count: count +1})
    if(this.state.count===5)
    {
      this.idle()
      clearInterval(this.timerInterval)
      setTimeout(() => {
        this.changeToText();
      }, 2500);
    }
  }
  moveItem5=(a)=>{
    TweenMax.to(a,0.5,{width:'320px',top:'-200px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  chooseItem5=(a)=>{
    TweenMax.to(a,0.5,{top:'500px',width:'100px'})
  }
  selectFood6=()=>{
    let {type}=this.state
    type.push('food-6')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-1")
    this.hideitem("#food-1,#food-2,#food-3,#food-4,#food-5")
    this.moveItem6("#food-6");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#food-6");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#food,#food-label,#categories")
      this.chooseItem6("#food-6")
      this.hideCategories2("#food");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-1")
    }, 2000);
    let {count} = this.state
    this.setState({count: count +1})
    if(this.state.count===5)
    {
      this.idle()
      clearInterval(this.timerInterval)
      setTimeout(() => {
        this.changeToText();
      }, 2500);
    }
  }
  moveItem6=(a)=>{
    TweenMax.to(a,0.5,{width:'320px',left:'-260px',top:'-200px',transform: 'rotateX(180deg)',pointerEvents:'none'})
  }
  chooseItem6=(a)=>{
    TweenMax.to(a,0.5,{top:'500px',width:'100px'})
  }


  selectTransport1=()=>{
    let {type}=this.state
    type.push('transport-1')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-2,#transport-3,#transport-4,#transport-5,#transport-6")
      this.moveItem1("#transport-1");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-1");
      },500)
      setTimeout(() => {
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem1("#transport-1")
        this.selectItemSound();
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count + 1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectTransport2=()=>{
    let {type}=this.state
    type.push('transport-2')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-1,#transport-3,#transport-4,#transport-5,#transport-6")
      this.moveItem2("#transport-2");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-2");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem2("#transport-2")
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectTransport3=()=>{
    let {type}=this.state
    type.push('transport-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-1,#transport-2,#transport-4,#transport-5,#transport-6")
      this.moveItem3("#transport-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem3("#transport-3")
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectTransport4=()=>{
    let {type}=this.state
    type.push('transport-4')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-1,#transport-2,#transport-3,#transport-5,#transport-6")
      this.moveItem4("#transport-4");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-4");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem4("#transport-4")
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectTransport5=()=>{
    let {type}=this.state
    type.push('transport-5')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-1,#transport-2,#transport-3,#transport-4,#transport-6")
      this.moveItem5("#transport-5");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-5");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem5("#transport-5")
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectTransport6=()=>{
    let {type}=this.state
    type.push('transport-6')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-2")
      this.hideitem("#transport-1,#transport-2,#transport-3,#transport-4,#transport-5")
      this.moveItem6("#transport-6");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#transport-6");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#transport,#categories,#transport-label")
        this.chooseItem6("#transport-6")
        this.hideCategories2("#transport");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-2")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }



  selectLux1=()=>{
    let {type}=this.state
    type.push('lux-1')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-3")
      this.hideitem("#lux-2,#lux-3,#lux-4,#lux-5,#lux-6")
      this.moveItem1("#lux-1");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#lux-1");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#lux,#categories,#lux-label")
        this.chooseItem1("#lux-1")
        this.hideCategories2("#lux");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-3")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectLux2=()=>{
    let {type}=this.state
    type.push('lux-2')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-3")
      this.hideitem("#lux-1,#lux-3,#lux-4,#lux-5,#lux-6")
      this.moveItem2("#lux-2");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#lux-2");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#lux,#lux-label")
        this.chooseItem2("#lux-2")
        this.hideCategories2("#lux");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-3")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectLux3=()=>{
    let {type}=this.state
    type.push('lux-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-3")
      this.hideitem("#lux-1,#lux-2,#lux-4,#lux-5,#lux-6")
      this.moveItem3("#lux-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#lux-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#lux,#lux-label")
        this.chooseItem3("#lux-3")
        this.hideCategories2("#lux");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-3")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectLux4=()=>{
    let {type}=this.state
    type.push('lux-4')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-3")
      this.hideitem("#lux-1,#lux-2,#lux-3,#lux-5,#lux-6")
      this.moveItem4("#lux-4");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#lux-4");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#lux,#lux-label")
        this.chooseItem4("#lux-4")
        this.hideCategories2("#lux");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-3")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectLux5=()=>{
    let {type}=this.state
    type.push('lux-5')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-3")
      this.hideitem("#lux-1,#lux-2,#lux-3,#lux-4,#lux-6")
      this.moveItem5("#lux-5");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#lux-5");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#lux,#lux-label")
        this.chooseItem5("#lux-5")
        this.hideCategories2("#lux");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-3")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectLux6=()=>{
    let {type}=this.state
    type.push('lux-6')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-3")
    this.hideitem("#lux-1,#lux-2,#lux-3,#lux-4,#lux-5")
    this.moveItem6("#lux-6");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#lux-6");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#categories,#lux,#lux-label")
      this.chooseItem6("#lux-6")
      this.hideCategories2("#lux");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-3")
    }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }






  selectBook1=()=>{
    let {type}=this.state
    type.push('book-1')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-4")
    this.hideitem("#book-2,#book-3,#book-4,#book-5,#book-6")
    this.moveItem1("#book-1");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#book-1");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#categories,#book,#book-label")
      this.chooseItem1("#book-1")
      this.hideCategories2("#book");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-4")
    }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectBook2=()=>{
    let {type}=this.state
    type.push('book-2')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-4")
      this.hideitem("#book-1,#book-3,#book-4,#book-5,#book-6")
      this.moveItem2("#book-2");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#book-2");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#book,#book-label")
        this.chooseItem2("#book-2")
        this.hideCategories2("#book");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-4")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectBook3=()=>{
    let {type}=this.state
    type.push('book-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-4")
      this.hideitem("#book-1,#book-2,#book-4,#book-5,#book-6")
      this.moveItem3("#book-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#book-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#book,#book-label")
        this.chooseItem3("#book-3")
        this.hideCategories2("#book");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-4")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectBook4=()=>{
    let {type}=this.state
    type.push('book-4')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-4")
      this.hideitem("#book-1,#book-2,#book-3,#book-5,#book-6")
      this.moveItem4("#book-4");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#book-4");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#book,#book-label")
        this.chooseItem4("#book-4")
        this.hideCategories2("#book");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-4")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectBook5=()=>{
    let {type}=this.state
    type.push('book-5')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-4")
      this.hideitem("#book-1,#book-2,#book-3,#book-4,#book-6")
      this.moveItem5("#book-5");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#book-5");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#book,#book-label")
        this.chooseItem5("#book-5")
        this.hideCategories2("#book");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-4")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectBook6=()=>{
    let {type}=this.state
    type.push('book-6')
    this.setState({type})
    console.log(this.state.type)
    this.hideSelect("#ctg-4")
    this.hideitem("#book-1,#book-2,#book-3,#book-4,#book-5")
    this.moveItem6("#book-6");
    this.selectItemSound();

    setTimeout(()=>{
      this.moveItem22("#book-6");
    },500)
    setTimeout(() => {
      this.selectItemSound();
      this.hideCategories("#categories,#book,#book-label")
      this.chooseItem6("#book-6")
      this.hideCategories2("#book");
    },1000);
    setTimeout(() => {
      this.showCategories("#categories,#categoires-label");
      this.showSelect("#ctg-select-4")
    }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }



  selectMemory1=()=>{
    let {type}=this.state
    type.push('memory-1')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-2,#memory-3,#memory-4,#memory-5,#memory-6")
      this.moveItem1("#memory-1");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#memory-1");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem1("#memory-1")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectMemory2=()=>{
    let {type}=this.state
    type.push('memory-2')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-1,#memory-3,#memory-4,#memory-5,#memory-6")
      this.moveItem2("#memory-2");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#memory-2");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem2("#memory-2")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectMemory3=()=>{
    let {type}=this.state
    type.push('memory-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-1,#memory-2,#memory-4,#memory-5,#memory-6")
      this.moveItem3("#memory-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#memory-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem3("#memory-3")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectMemory4=()=>{
    let {type}=this.state
    type.push('memory-4')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-1,#memory-2,#memory-3,#memory-5,#memory-6")
      this.moveItem4("#memory-4");
      this.selectItemSound();
      setTimeout(()=>{
        this.moveItem22("#memory-4");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem4("#memory-4")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectMemory5=()=>{
    let {type}=this.state
    type.push('memory-5')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-1,#memory-2,#memory-3,#memory-4,#memory-6")
      this.moveItem5("#memory-5");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#memory-5");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem5("#memory-5")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectMemory6=()=>{
    let {type}=this.state
    type.push('memory-6')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-5")
      this.hideitem("#memory-1,#memory-2,#memory-3,#memory-4,#memory-5")
      this.moveItem6("#memory-6");
      this.selectItemSound();
      setTimeout(()=>{
        this.moveItem22("#memory-6");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#memory,#memory-label")
        this.chooseItem6("#memory-6")
        this.hideCategories2("#memory");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-5")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }

  selectCulture1=()=>{
    let {type}=this.state
    type.push('culture-1')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-6")
      this.hideitem("#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
      this.moveItem1("#culture-1");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#culture-1");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem1("#culture-1")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectCulture2=()=>{
    let {type}=this.state
    type.push('culture-2')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-6")
      this.hideitem("#culture-1,#culture-3,#culture-4,#culture-5,#culture-6")
      this.moveItem2("#culture-2");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#culture-2");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem2("#culture-2")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectCulture3=()=>{
    let {type}=this.state
    type.push('culture-3')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-6")
      this.hideitem("#culture-1,#culture-2,#culture-4,#culture-5,#culture-6")
      this.moveItem3("#culture-3");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#culture-3");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem3("#culture-3")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectCulture4=()=>{
    let {type}=this.state
    type.push('culture-4')
    this.setState({type})
    console.log(this.state.type)
      this.hideSelect("#ctg-6")
      this.hideitem("#culture-1,#culture-2,#culture-3,#culture-5,#culture-6")
      this.moveItem4("#culture-4");
      this.selectItemSound();
      setTimeout(()=>{
        this.moveItem22("#culture-4");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem4("#culture-4")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectCulture5=()=>{
      let {type}=this.state
      type.push('culture-5')
      this.setState({type})
      console.log(this.state.type)
      this.hideSelect("#ctg-6")
      this.hideitem("#culture-1,#culture-2,#culture-3,#culture-4,#culture-6")
      this.moveItem5("#culture-5");
      this.selectItemSound();

      setTimeout(()=>{
        this.moveItem22("#culture-5");
      },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem5("#culture-5")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
        this.showCategories("")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        // this.setState({time:30})
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
  selectCulture6=()=>{
    let {type}=this.state
    type.push('culture-6')
    this.setState({type})
    console.log(this.state.type)      
    this.hideSelect("#ctg-6")
    this.hideitem("#culture-1,#culture-2,#culture-3,#culture-4,#culture-5")
    this.moveItem6("#culture-6");
    setTimeout(()=>{
      this.moveItem22("#culture-6");
            this.selectItemSound();
    },500)
      setTimeout(() => {
        this.selectItemSound();
        this.hideCategories("#categories,#culture,#culture-label")
        this.chooseItem6("#culture-6")
        this.hideCategories2("#culture");
      },1000);
      setTimeout(() => {
        this.showCategories("#categories,#categoires-label");
        this.showSelect("#ctg-select-6")
      }, 2000);
      let {count} = this.state
      this.setState({count: count +1})
      if(this.state.count===5)
      {
        this.idle()
        clearInterval(this.timerInterval)
        this.setState({time:30})
        setTimeout(() => {
          this.changeToText();
        }, 2500);
      }
  }
 
onFocus = (i) => {
  this.setState({input_no:i })
}




renderKeyboard2 = () => {
  const {text,agrees  } = this.state

  return(
     <div id="keyboard"  > 
     <span className="label">Email</span><input id={text.email} type="text" placeholder="Enter email to receive your time capsule message" className="text-input-3" value={text.email} onFocus={()=>this.onFocus('email')}/><br></br>
     <div onClick={()=>this.toggleAgree(0)}>
                        <div className="agrees" >
                           {agrees[0] ?
                              <img alt="" src={AgreeTick} className="agree-tick" />
                              :
                              <img alt="" src={AgreeCross} className="agree-cross" />
                           }
                           <p className="pdpa">PDPA Consent</p>
                        </div>
                        <p className="texts">I confirm that I have read the Terms & Conditions and PDPA Consent <br/>above and agree to the terms thereto</p>

                     </div>
     <div className="krows" id="row1">
        <span onClick={(e)=>this.handleKey(e)} data-id="1">1</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="2">2</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="3">3</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="4">4</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="5">5</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="6">6</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="7">7</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="8">8</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="9">9</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="0">0</span>
     </div>
     <div className="krows" id="row2">
        <span onClick={(e)=>this.handleKey(e)} data-id="q">Q</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="w">W</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="e">E</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="r">R</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="t">T</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="y">Y</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="u">U</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="i">I</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="o">O</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="p">P</span>
        <span onClick={()=>this.handleDelete()}>Delete</span>

     </div>
     <div className="krows" id="row3">
        <span onClick={(e)=>this.handleKey(e)} data-id="a">A</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="s">S</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="d">D</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="f">F</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="g">G</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="h">H</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="j">J</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="k">K</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="l">L</span>
        <span onClick={()=>this.handleReturn()}>return</span>
     </div>
     <div className="krows" id="row4">
        <span onClick={(e)=>this.handleKey(e)} data-id="z">Z</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="x">X</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="c">C</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="v">V</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="b">B</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="n">N</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="m">M</span>
        <span onClick={(e)=>this.handleKey(e)} data-id=".">.</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="-">-</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="_">_</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="@">@</span>
     </div>
     <div className="krows" id="row5">
        <span onClick={(e)=>this.handleKey(e)} data-id="@gmail.com">@gmail.com</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="@hotmail.com">@hotmail.com</span>
        <span onClick={(e)=>this.handleKey(e)} data-id=" " className="keyboard-space">Space</span>
        <span onClick={(e)=>this.handleKey(e)} data-id="@yahoo.com">@yahoo.com</span>
        {/*<span onClick={(e)=>this.handleKey(e)} data-id=".com">.com</span>*/}
     </div>
     <div><img src={submitbtn} alt="" className="submit-btn" id="submit-btn" onClick={()=>this.handleReturn()}/></div>
  </div>
)
}
resetIdle=()=>{
  clearTimeout(this.idle_timer);
  this.idle_timer=setTimeout(()=>{
    this.toHome4();
  },60000)
}
handleKey = (e) => {
  this.resetIdle();
let {text,email_valids, input_no} = this.state
console.log("inputno",input_no)

let text2 = e.currentTarget.dataset.id
if(input_no==='name')
{
  let textText = ''
  textText = text.name + text2
    text.name = textText
  console.log("after",text.name)
  
  this.setState({
    text
  })
  console.log("text",text)
}
if(input_no==='message')
{
  let textText = ''
  textText = text.message + text2
  console.log("before",textText)
    text.message = textText
  console.log("after",text.message)
    if(input_no==='email')
    {
      // email_valid.input_no = this.validateEmail(textText)
    }
  // emailText = emails[0] + text2
  // emails[input_no2] = emailText
  //  email_valids[input_no2] = this.validateEmail(emailText)
  
  this.setState({
    text,
  })
  console.log("text",text)
}
if(input_no==='email')
{
  let textText = ''
  textText = text.email + text2
  console.log("before",textText)
    text.email = textText
  console.log("after",text.email)
     email_valids = this.validateEmail(textText)
  // emailText = emails[0] + text2
  // emails[input_no2] = emailText
  //  email_valids[input_no2] = this.validateEmail(emailText)
  
  this.setState({
    text, email_valids
    
  })
  console.log("emailvaild",email_valids)
  console.log("text",text)
}
}
validateEmail = (email) => {
var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
return re.test(String(email).toLowerCase())
}
handleDelete = () => {
let {text,emails, input_no, email_valids} = this.state
if(input_no==="name")
{
  if(text.name.length <= 0) {
    return
  }
  let textText = ''

textText = text[input_no].slice(0, text.name.length - 1)
// emailText = emails[input_no2].slice(0, emails[input_no2].length - 1)alt="" 
text.name = textText

// emails[input_no2] = emailText
// email_valids[input_no2] = this.validateEmail(emailText)
this.setState({
  text,emails, email_valids,
})
}

if(input_no==="message")
{
  if(text.message.length <= 0) {
    return
  }
  let textText = ''

textText = text.message.slice(0, text.message.length - 1)
// emailText = emails[input_no2].slice(0, emails[input_no2].length - 1)alt="" 
text.message = textText

// emails[input_no2] = emailText
// email_valids[input_no2] = this.validateEmail(emailText)
this.setState({
  text,emails, email_valids,
})
}
if(input_no==="email")
{
  if(text.email.length <= 0) {
    return
  }
  let textText = ''

textText = text.email.slice(0, text.email.length - 1)
// emailText = emails[input_no2].slice(0, emails[input_no2].length - 1)alt="" 
text.email= textText

// emails[input_no2] = emailText
email_valids = this.validateEmail(textText)
this.setState({
  text,emails, email_valids,
})
}

}
capitalLetter=(str)=> 
    {
    str = str.split(" ");

    for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  }
handleReturn = () => {
  console.log("length",this.state.text.name.length)
  // if(this.state.text.name.length)
  if(this.state.agrees[0]===true && this.state.email_valids===true && this.state.text.name.length>0 && this.state.text.message.length>0)
  {
    this.hideCategories("#food-1,#food-2,#food-3,#food-4,#food-5,#food-6,#transport-1,#transport-2,#transport-3,#transport-4,#transport-5,#transport-6,#lux-1,#lux-2,#lux-3,#lux-4,#lux-5,#lux-6,#memory-1,#memory-2,#memory-3,#memory-4,#memory-5,#memory-6,#book-1,#book-2,#book-3,#book-4,#book-5,#book-6,#culture-1,#culture-2,#culture-3,#culture-4,#culture-5,#culture-6")
    setTimeout(() => {
      for(var i=0;i<=5;i++)
      {
      console.log(this.state.type[i])
      this.moveOrigin2('#'+this.state.type[i])
      }
    }, 1000);
    
    setTimeout(() => {
      this.toPoster();
      this.showHomeText("#home-text")
    }, 10000);
      let c = document.getElementById("myCanvas");
      let ctx = c.getContext("2d");
      let main_img = document.getElementById("scream");
        ctx.drawImage(main_img, 0,0)
        ctx.font = "800 35px Din";
        ctx.textAlign = "center";
        ctx.fillText(this.capitalLetter(this.state.text.name),c.width/2,1240)

        ctx.font = "600 25px Din";
        ctx.fillText(this.capitalLetter(this.state.text.message),c.width/2,1270)

        let img1 = document.getElementById(this.state.type[0]);
        ctx.drawImage(img1, 40,1330,160,160)
        let img2 = document.getElementById(this.state.type[1]);
        ctx.drawImage(img2, 210,1330,160,160)
        let img3 = document.getElementById(this.state.type[2]);
        ctx.drawImage(img3, 380,1330,160,160)
        let img4 = document.getElementById(this.state.type[3]);
        ctx.drawImage(img4, 550,1330,160,160)
        let img5 = document.getElementById(this.state.type[4]);
        ctx.drawImage(img5, 720,1330,160,160)
        let img6 = document.getElementById(this.state.type[5]);
        ctx.drawImage(img6, 890,1330,160,160)
        let finalImage = c.toDataURL("image/jpeg")
        console.log("final image",finalImage)
        let formData = new FormData()
        formData.append("name", this.state.text.name)
        console.log("send image",this.state.text.name)
  
        formData.append("message", this.state.text.message)
        formData.append("email", this.state.text.email)
        formData.append("img", finalImage)
        console.log("send image",finalImage)
        fetch("https://artscience.wunderfauks.com/wp/wp-admin/admin-ajax.php?action=email_submit",{
           method: "POST",
           body: formData
        })
        .then(response => response.json())
        .then(json => {
          
        })
        socket.emit('hey', 'done');
        this.toHash()
        clearTimeout(this.idle_timer);
    }
  if(this.state.email_valids===false)
  {
    this.popupOpen2();
  }
  if(this.state.agrees[0]===false)
  {
    this.popupOpen3();
  }
  if(this.state.text.name.length===0)
  {
    this.popupOpen4();
  }
  if(this.state.text.message.length===0)
  {
    this.popupOpen5();
  }
}
toggleAgree = (id) => {
let {agrees} = this.state
agrees[id] = !agrees[id]
this.setState({ agrees })
}


slideOne=()=>{
  this.showSlide("#exp-slide-1");
  this.showSlideIcon("#slide-icon-select-1");
  this.hideSlideIcon("#slide-icon-select-2");
  this.hideSlideIcon("#slide-icon-select-3");
  this.hideSlideIcon("#start-btn,#finger")
  this.moveSlide2("#exp-slide-1");
  this.moveSlide3("#exp-slide-2");
  this.moveSlide3("#exp-slide-3");

  setTimeout(() => {
    this.slideTwo()
  }, 3000);
}
moveSlide=(a)=>{
  TweenMax.to(a,0.1,{'left':'-50%'})
}
moveSlide2=(a)=>{
  TweenMax.to(a,0.1,{'left':'32%'})
}
moveSlide3=(a)=>{
  TweenMax.to(a,0.1,{'left':'100%'})
}
slideTwo=()=>{
  this.showSlide("#exp-slide-2");
  this.moveSlide("#exp-slide-1");
  this.moveSlide2("#exp-slide-2");
  this.moveSlide3("#exp-slide-3");
  this.showSlideIcon("#slide-icon-select-2");
  this.hideSlideIcon("#slide-icon-select-1");
  this.hideSlideIcon("#slide-icon-select-3");
  this.hideSlideIcon("#start-btn,#finger")
  
  setTimeout(() => {
    this.slideThree()
  }, 3000);
}
slideThree=()=>{
  this.moveSlide("#exp-slide-2");
  this.moveSlide2("#exp-slide-3");
  this.showSlide("#exp-slide-3");
  this.showSlideIcon("#slide-icon-select-3");
  this.hideSlideIcon("#slide-icon-select-1");
  this.hideSlideIcon("#slide-icon-select-2");
  this.showSlideIcon("#start-btn");
  this.showSlideIcon("#finger");
}
showSlide=(a)=>{
  TweenMax.to(a,1,{display:'inline'})
}
hideSlide=(a)=>{
  TweenMax.to(a,0.1,{display:'none'})
}
showSlideIcon=(a)=>{
  TweenMax.to(a,0.1,{display:'inline',zIndex:'2'})
}
hideSlideIcon=(a)=>{
  TweenMax.to(a,0.1,{display:'none',zIndex:'0'})
}

popupOpen =()=>{
  document.getElementById('popup').style.display="block"
}
popupOpen2 =()=>{
  document.getElementById('popup-2').style.display="block"
}
popupOpen3 =()=>{
  document.getElementById('popup-3').style.display="block"
}
popupOpen4 =()=>{
  document.getElementById('popup-4').style.display="block"
}
popupOpen5 =()=>{
  document.getElementById('popup-5').style.display="block"
}
popupClose =()=>{
  document.getElementById('popup').style.display="none"
}
popupClose2 =()=>{
  document.getElementById('popup-2').style.display="none"
}
popupClose3 =()=>{
  document.getElementById('popup-3').style.display="none"
}
popupClose4 =()=>{
  document.getElementById('popup-4').style.display="none"
}
popupClose5 =()=>{
  document.getElementById('popup-5').style.display="none"
}
antimationSound = () => {
  this.refs.startSound.play();
 }
openCapsuleSound = () => {
  this.refs.openCapsuleSound.play();
 }

selectItemSound = () => {
  // var audioElement = new Audio(selectsound);
  // audioElement.play();
  this.refs.selectSound.play();
 }

render(){
  const {timer,text,data}=this.state
  return(
    <div id="app">
      <audio ref="startSound" src={startsound}></audio>
      <audio ref="openCapsuleSound" src={pon}></audio>
      <audio ref="selectSound" src={selectsound}></audio>
      <div className="pages" id="home">
      <audio src={bgmusic} autoPlay loop/>
      <img src={swp} alt="" className="swp" id="swp"/>
  {/*<div className="home-btn" id="home-btn" onClick={this.onBegin}></div>*/}
        <img src={homebtnicon}className="home-btn-icon" alt="" id="home-btn-icon" onClick={this.onBegin}/>
        {/*<div className="capsule-count" id="capsule-count">{data.length}</div>*/}
        <img src={finger} alt="" className="finger" ></img>
          {<img src={capsule2} alt="" className="capsule-home" id="capsule5" />}
          {<img src={capsule2} alt="" className="capsule-home" id="capsule6" />}
          {<img src={capsule2} alt="" className="capsule-home" id="capsule7" />}
          {<img src={capsule2} alt="" className="capsule-home" id="capsule8" />}
          {data.map((text,id)=>(
            <div className="home-text" id={`home-text-${id}`}>
              <div className="name" id={`home-name-${id}`}>{text[0]}</div>
              <div className="message" id={`home-message-${id}`}>{text[1]}</div>
          </div>
          ))}
          <div className="antimation" id="antimation">
         
          <div className="ring_21" id="ring_21"></div>
          <div className="ring_22" id="ring_22"></div>
          <div className="ring_23"></div>
          <div className="ring_24"></div>
          <div className="ring_25" id="ring_25"></div>
          
        </div>
          <div className="counter">
          <span className={`digit-${parseInt(data.length/10000)}`}></span>
          <span className={`digit-${parseInt(data.length/1000)}`}></span>
          <span className={`digit-${parseInt(data.length/100)}`}></span>
          <span className={`digit-${parseInt(data.length/10)}`}></span>
          <span className={`digit-${data.length%10}`}>{data.length%10}</span>
          <img src={countLabel} alt="" className="count-label" />
          </div>
      </div>

      <div className="pages" id="anti">
        
      </div>

      <div className="pages" id="explanation">
        <div className="htp" id="htp">HOW TO PLAY</div>
        <img src={exp1} alt="" className="exp-slide" id="exp-slide-1" />
        <img src={exp2} alt="" className="exp-slide" id="exp-slide-2"/>
        <img src={exp3} alt="" className="exp-slide" id="exp-slide-3"/>
        <img src={siSelect} alt="" className="slide-icon-select" id="slide-icon-select-1"/>
        <img src={si} alt="" className="slide-icon" id="slide-icon-1" onClick={this.slideOne}/>

        <img src={siSelect} alt="" className="slide-icon-select" id="slide-icon-select-2"/>
        <img src={si} alt="" className="slide-icon" id="slide-icon-2" onClick={this.slideTwo}/>

        <img src={siSelect} alt="" className="slide-icon-select" id="slide-icon-select-3"/>
        <img src={si} alt="" className="slide-icon" id="slide-icon-3" onClick={this.slideThree}/>

        <img src={start} alt="" className="start-btn" id="start-btn" onClick={this.toReady}/>
        <img src={finger} alt="" className="finger2" id="finger"></img>
        <img src={homeBtn} alt="" className="homebtn" id="homebtn" onClick={this.toHome2}/>
        <img src={footer1} alt="" className="footer1" /> 
        <img src={footer2} alt="" className="footer2" /> 

      </div>
      <div className="pages" id="ready">
        <img src={timeup} alt="" className="timeup" id="timeup"/>
        <img src={homeBtn} alt="" className="homebtn"  onClick={this.readyToHome}/>
        <div className="ready" id="get-ready">GET<br></br>READY</div>
        <img src={readyText} alt="" className="ready-text" id="ready-text"></img>
        <div className="timer">{timer}</div>
        <img src={cover} alt="" className="cover" id="cover"/>
        <img src={handle} alt="" className="handle" id="handle"/>
        <img src={handle2} alt="" className="handle2" id="handle2"/>
        <div className="categories" id="categories">
          <img src={food} alt="" className="categories-1" id="ctg-1" onClick={this.toFood}/>
          <img src={foodselect} alt="" className="categories-1" id="ctg-select-1"/>
          <img src={transport} alt="" className="categories-1" id="ctg-2" onClick={this.toTransport}/>
          <img src={transportselect} alt="" className="categories-1" id="ctg-select-2"/>
          <img src={littleLuxuries} alt="" className="categories-1" id="ctg-3" onClick={this.toLuxuries}/>
          <img src={luxselect} alt="" className="categories-1" id="ctg-select-3"/>
          <img src={book} alt="" className="categories-1" id="ctg-4" onClick={this.toBook}/>
          <img src={bookselect} alt="" className="categories-1" id="ctg-select-4"/>
          <img src={memory} alt="" className="categories-1" id="ctg-5" onClick={this.toMemory}/>
          <img src={memoryselect} alt="" className="categories-1" id="ctg-select-5"/>
          <img src={singaporeCulture} alt="" className="categories-1" id="ctg-6" onClick={this.toCulture}/>
          <img src={cultureselect} alt="" className="categories-1" id="ctg-select-6"/>
        </div>

       

        <div className="categories" id="food">
          <img src={food1} alt="" className="categories-2" id="food-1" onClick={this.selectFood1}/>
          <img src={food2} alt="" className="categories-2" id="food-2" onClick={this.selectFood2}/>
          <img src={food3} alt="" className="categories-2" id="food-3" onClick={this.selectFood3}/>
          <img src={food4} alt="" className="categories-2" id="food-4" onClick={this.selectFood4}/>
          <img src={food5} alt="" className="categories-2" id="food-5" onClick={this.selectFood5}/>
          <img src={food6} alt="" className="categories-2" id="food-6" onClick={this.selectFood6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>
        <div className="categories" id="transport">
          <img src={transport1} alt="" className="categories-2" id="transport-1" onClick={this.selectTransport1}/>
          <img src={transport2} alt="" className="categories-2" id="transport-2" onClick={this.selectTransport2}/>
          <img src={transport3} alt="" className="categories-2" id="transport-3" onClick={this.selectTransport3}/>
          <img src={transport4} alt="" className="categories-2" id="transport-4" onClick={this.selectTransport4}/>
          <img src={transport5} alt="" className="categories-2" id="transport-5" onClick={this.selectTransport5}/>
          <img src={transport6} alt="" className="categories-2" id="transport-6" onClick={this.selectTransport6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>
        <div className="categories" id="lux">
          <img src={lux1} alt="" className="categories-2" id="lux-1" onClick={this.selectLux1}/>
          <img src={lux2} alt="" className="categories-2" id="lux-2" onClick={this.selectLux2}/>
          <img src={lux3} alt="" className="categories-2" id="lux-3" onClick={this.selectLux3}/>
          <img src={lux4} alt="" className="categories-2" id="lux-4" onClick={this.selectLux4}/>
          <img src={lux5} alt="" className="categories-2" id="lux-5" onClick={this.selectLux5}/>
          <img src={lux6} alt="" className="categories-2" id="lux-6" onClick={this.selectLux6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>

        <div className="categories" id="book">
          <img src={book1} alt="" className="categories-2" id="book-1" onClick={this.selectBook1}/>
          <img src={book2} alt="" className="categories-2" id="book-2" onClick={this.selectBook2}/>
          <img src={book3} alt="" className="categories-2" id="book-3" onClick={this.selectBook3}/>
          <img src={book4} alt="" className="categories-2" id="book-4" onClick={this.selectBook4}/>
          <img src={book5} alt="" className="categories-2" id="book-5" onClick={this.selectBook5}/>
          <img src={book6} alt="" className="categories-2" id="book-6" onClick={this.selectBook6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>

        <div className="categories" id="memory">
          <img src={memory1} alt="" className="categories-2" id="memory-1" onClick={this.selectMemory1}/>
          <img src={memory2} alt="" className="categories-2" id="memory-2" onClick={this.selectMemory2}/>
          <img src={memory3} alt="" className="categories-2" id="memory-3" onClick={this.selectMemory3}/>
          <img src={memory4} alt="" className="categories-2" id="memory-4" onClick={this.selectMemory4}/>
          <img src={memory5} alt="" className="categories-2" id="memory-5" onClick={this.selectMemory5}/>
          <img src={memory6} alt="" className="categories-2" id="memory-6" onClick={this.selectMemory6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>

        <div className="categories" id="culture">
          <img src={culture1} alt="" className="categories-2" id="culture-1" onClick={this.selectCulture1}/>
          <img src={culture2} alt="" className="categories-2" id="culture-2" onClick={this.selectCulture2}/>
          <img src={culture3} alt="" className="categories-2" id="culture-3" onClick={this.selectCulture3}/>
          <img src={culture4} alt="" className="categories-2" id="culture-4" onClick={this.selectCulture4}/>
          <img src={culture5} alt="" className="categories-2" id="culture-5" onClick={this.selectCulture5}/>
          <img src={culture6} alt="" className="categories-2" id="culture-6" onClick={this.selectCulture6}/>
          <img src={capsule} alt="" className="capsule" id="capsule" />
        </div>
        <img src={footer1} alt="" className="footer1" /> 
        <img src={footer2} alt="" className="footer2" /> 
      </div>
      <div className="pages" id="textarea">
      <img src={homeBtn} alt="" className="homebtn" id="homebtn-3" onClick={this.toHome4}/>
      <img src={skip} alt="" className="skipbtn" id="skip" onClick={this.toSkip}/>
          <img src={capsule2} alt="" className="capsule" id="capsule2" />
          <div className="text-area" id="text-area">
            <input id="name" type="text" className="text-input" placeholder="Enter your name" value={text.name} onFocus={()=>this.onFocus('name')}/><br></br>
            <input id="message" type="text" className="text-input-2" placeholder="Enter your message" value={text.message} onFocus={()=>this.onFocus('message')}/>

          </div>
          {this.renderKeyboard2()}
          <div className="hidden-btn" id="popup-btn" onClick={this.popupOpen} />
               <div className="hover_bkgr_fricc" id="popup">
                  <span className="helper"></span>
                  <div className="popup-text">
                     <div className="popupCloseButton" onClick={this.popupClose}>x</div>
                     <h5 className="popup-title">Consent</h5>
                     <p className="popup-body">I consent to Marina Bay Sands (MBS) and its service providers (whether
                      located within or outside Singapore) collecting, using or disclosing
                      my personal data (whether in MBS' possession or collected by any of
                      the said parties from time to time) to understand my preferences and
                      for the ArtScience Museum's marketing, promotional and other
                      business related purposes.To withdraw consent, visit http://bit.ly/mbs-pdpa
                      </p>
                      <div className="qr-area">
                        <img src={qrCode} alt="" className="qr-code"/>
                      </div>
                  </div>
            </div>
            <div className="hover_bkgr_fricc" id="popup-3">
            <span className="helper"></span>
            <div className="popup-text">
                <div className="popupCloseButton" onClick={this.popupClose3}>x</div>
                <h5 className="popup-title">Please consent to continue!</h5>
            </div>
          </div>
          
            <div className="hover_bkgr_fricc" id="popup-2">
              <span className="helper"></span>
              <div className="popup-text">
                  <div className="popupCloseButton" onClick={this.popupClose2}>x</div>
                  <h5 className="popup-title">Email is not valid!</h5>
              </div>
            </div>

            
            <div className="hover_bkgr_fricc" id="popup-5">
              <span className="helper"></span>
              <div className="popup-text">
                  <div className="popupCloseButton" onClick={this.popupClose5}>x</div>
                  <h5 className="popup-title">Please enter message to continue!</h5>
              </div>
            </div>
            <div className="hover_bkgr_fricc" id="popup-4">
              <span className="helper"></span>
              <div className="popup-text">
                  <div className="popupCloseButton" onClick={this.popupClose4}>x</div>
                  <h5 className="popup-title">Please enter name to continue!</h5>
              </div>
            </div>

            
            <img src={footer} alt="" className="footer" /> 

      </div>

      <div className="pages" id="hasharea">
      <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'600px',left:'75px'}}>
          <img src={food1} alt="" className="categories-1" id="food-1" />
          <img src={food2} alt="" className="categories-1" id="food-2" />
          <img src={food3} alt="" className="categories-1" id="food-3" />
          <img src={food4} alt="" className="categories-1" id="food-4" />
          <img src={food5} alt="" className="categories-1" id="food-5" />
          <img src={food6} alt="" className="categories-1" id="food-6" />
        </div>
        <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'600px',left:'75px'}}>
          <img src={transport1} alt="" className="categories-1" id="transport-1" onClick={this.selectTransport1}/>
          <img src={transport2} alt="" className="categories-1" id="transport-2" onClick={this.selectTransport2}/>
          <img src={transport3} alt="" className="categories-1" id="transport-3" onClick={this.selectTransport3}/>
          <img src={transport4} alt="" className="categories-1" id="transport-4" onClick={this.selectTransport4}/>
          <img src={transport5} alt="" className="categories-1" id="transport-5" onClick={this.selectTransport5}/>
          <img src={transport6} alt="" className="categories-1" id="transport-6" onClick={this.selectTransport6}/>
        </div>
        <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'600px',left:'75px'}}>
          <img src={lux1} alt="" className="categories-1" id="lux-1" onClick={this.selectLux1}/>
          <img src={lux2} alt="" className="categories-1" id="lux-2" onClick={this.selectLux2}/>
          <img src={lux3} alt="" className="categories-1" id="lux-3" onClick={this.selectLux3}/>
          <img src={lux4} alt="" className="categories-1" id="lux-4" onClick={this.selectLux4}/>
          <img src={lux5} alt="" className="categories-1" id="lux-5" onClick={this.selectLux5}/>
          <img src={lux6} alt="" className="categories-1" id="lux-6" onClick={this.selectLux6}/>
        </div>

        <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'720px',left:'75px'}}>
          <img src={book1} alt="" className="categories-1" id="book-1" onClick={this.selectBook1}/>
          <img src={book2} alt="" className="categories-1" id="book-2" onClick={this.selectBook2}/>
          <img src={book3} alt="" className="categories-1" id="book-3" onClick={this.selectBook3}/>
          <img src={book4} alt="" className="categories-1" id="book-4" onClick={this.selectBook4}/>
          <img src={book5} alt="" className="categories-1" id="book-5" onClick={this.selectBook5}/>
          <img src={book6} alt="" className="categories-1" id="book-6" onClick={this.selectBook6}/>
        </div>

        <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'720px',left:'75px'}}>
          <img src={memory1} alt="" className="categories-1" id="memory-1" onClick={this.selectMemory1}/>
          <img src={memory2} alt="" className="categories-1" id="memory-2" onClick={this.selectMemory2}/>
          <img src={memory3} alt="" className="categories-1" id="memory-3" onClick={this.selectMemory3}/>
          <img src={memory4} alt="" className="categories-1" id="memory-4" onClick={this.selectMemory4}/>
          <img src={memory5} alt="" className="categories-1" id="memory-5" onClick={this.selectMemory5}/>
          <img src={memory6} alt="" className="categories-1" id="memory-6" onClick={this.selectMemory6}/>
        </div>

        <div className="categories" style={{position:'relative',width:'200px',padding:'50px',float:'left',top:'720px',left:'75px'}}>
          <img src={culture1} alt="" className="categories-1" id="culture-1" onClick={this.selectCulture1}/>
          <img src={culture2} alt="" className="categories-1" id="culture-2" onClick={this.selectCulture2}/>
          <img src={culture3} alt="" className="categories-1" id="culture-3" onClick={this.selectCulture3}/>
          <img src={culture4} alt="" className="categories-1" id="culture-4" onClick={this.selectCulture4}/>
          <img src={culture5} alt="" className="categories-1" id="culture-5" onClick={this.selectCulture5}/>
          <img src={culture6} alt="" className="categories-1" id="culture-6" onClick={this.selectCulture6}/>
        </div>
          <img src={capsule2} alt="" className="capsule" id="capsule4" />
          
          <div className="text-area" id="hash-area">
            <div className="name" style={{width:'100%',textAlign:'center'}}>{this.state.text.name}</div>
            <div className="message" style={{width:'100%',textAlign:'center'}}>{this.state.text.message}</div>
          </div>
          <img id="scream" width="1080" height="1920" src={ss} alt="The Scream" style={{display:'none'}}/>
          <canvas id="myCanvas" width="1080" height="1920"></canvas>
      </div>
      <div className="pages" id="poster">
      
      </div>
    </div>
  )
}
}

export default App;
